<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col lg="12">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Access Level Name<span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.bul_name" @keyup="$parent.removeWildChar" @keydown="$parent.removeWildChar" placeholder="e.g Administrator">
                  </b-form-input>                  
                  <VValidate 
                    name="Access Level" 
                    v-model="row.bul_name" 
                    :rules="{required:1, min:3, max:60, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                  />

                </b-form-group>
              </b-col>

              <b-col v-if="row.bul_menu_role" lg=12>
                
                <b-row>
                  <b-col lg="10">
                    <b-form-group label="Access Rights">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th width="40">#</th>
                            <th width="50%">Module</th>
                            <th width="50%">Access Rights</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="row.bul_menu_role">
                            <tr v-for="(v, k) in (row.bul_menu_role||[])" :key="k">
                              <td>{{ k + 1 }}</td>
                              <td>
                                <div v-if="row.bul_menu_role[k].menu !== 'BoDashboard'">
                                  <v-select 
                                    :options="getMenu(k)"
                                    v-model="row.bul_menu_role[k]"
                                    placeholder="Pilih Modul akses"
                                    label="name"
                                    :reduce="(v) => v"
                                    @input="setMenu($event, k)"
                                    @open="setOverflowAuto"
                                    @close="setOverflowHidden"
                                  >
                                  </v-select>
                                </div> 
                                <div class="" v-else>
                                  Dashboard
                                </div>
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <b-badge variant="secondary mr-2">Read</b-badge>
                                  <div 
                                    v-if="getModule(v).length"
                                    style="min-width:80%"
                                  >

                                    <v-select 
                                      multiple 
                                      :options="getModule(v)" 
                                      v-model="v.module"
                                      label="name"
                                      :reduce="(v) => v.key"
                                      class="vs__multiple"
                                      :taggable="!isEqual(v.module.length, getModule(v).length)"
                                      :filterable="!isEqual(v.module.length, getModule(v).length)"
                                      placeholder="Pilih satu/lebih hak akses yang tersedia. Kosongkan jika tidak diberi akses." 
                                      @open="setOverflowAuto"
                                      @close="setOverflowHidden"
                                    >
                                    </v-select>

                        

                                  </div>
                                </div>
                              </td>
                              <td width="50px">
                                <div class="" v-if="row.bul_menu_role[k].menu !== 'BoDashboard'">
                                  <b-button @click="row.bul_menu_role.splice(k,1)" variant="outline-danger" class="ml-2 btn-icon btn-rounded">
                                    <i class="fas fa-trash"></i>
                                  </b-button>
                                </div>     
                              </td>
                            </tr>
                          </template>
                          <tr>
                            <td colspan="3">
                              <div class="text-center">
                                <b-button 
                                  variant="outline-info" 
                                  class="btn-rounded"
                                  @click="row.bul_menu_role.push({})"
                                  v-if="row.bul_menu_role.length < mrMenuRel.length"
                                >
                                  <i class="fas fa-plus mr-2"></i>
                                  Add Module Access
                                </b-button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <VValidate
                        name="Access Rights"
                        :value="isEmptyHakAkses?'':1"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-col>

              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status<span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
            <b-button type="submit" variant="primary" class="btn-rounded" @click="$parent.doSubmitCRUD('VForm', $refs)">Save Changes</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrMenuRel:Array,
  },
  data() {
    return {
      dataOpt : [
        {value : 1, label: 'tes 1'},
        {value : 2, label: 'tes 2'},
        {value : 3, label: 'tes 3'},
        {value : 4, label: 'tes 4'}
      ],
      valueInp : 1
    }
  },
  computed:{
    isEmptyHakAkses(){
      if(!(this.row.bul_menu_role||[]).length) return true
      const dtEmpty = _.filter(this.row.bul_menu_role, v=>{
        return !Object.keys(v).length
      })
      return !!dtEmpty.length
    }
  },
  methods:{
    getMenu(k){
      const sldMenu = _.map(_.filter(this.row.bul_menu_role||[], (v2, k2)=>{
        return k2!=k
      }), v2=>{
        return v2.menu
      })

      return _.filter(this.mrMenuRel, v2=>{
        return sldMenu.indexOf(v2.menu)<=-1
      })
    },
    setMenu(sldValue, key){
      let sldVal = _.clone(sldValue)
      sldVal.mrModule = _.clone(sldVal.module)
      sldVal.module = []
      this.$set(this.row.bul_menu_role, key, sldVal)
    },
    getModule(v){
      return (v||{}).mrModule||[]
    },
    doSubmitForm(){
      this.$emit('click:doSubmit', 'VForm', this.$refs)
    },
    isEqual(p1, p2){
      if(p1 == p2) return true
      return false
    },
    setOverflowAuto(){
      document.querySelector('.page-wrapper').style.overflow = 'auto'
    },
    setOverflowHidden(){
      document.querySelector('.page-wrapper').style.overflow = 'hidden'
    },
  }
}
</script>